(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("./foundation.accordion"), require("./foundation.core"), require("./foundation.tabs"), require("./foundation.util.mediaQuery"), require("jquery"));
	else if(typeof define === 'function' && define.amd)
		define(["./foundation.accordion", "./foundation.core", "./foundation.tabs", "./foundation.util.mediaQuery", "jquery"], factory);
	else if(typeof exports === 'object')
		exports["foundation.responsiveAccordionTabs"] = factory(require("./foundation.accordion"), require("./foundation.core"), require("./foundation.tabs"), require("./foundation.util.mediaQuery"), require("jquery"));
	else
		root["__FOUNDATION_EXTERNAL__"] = root["__FOUNDATION_EXTERNAL__"] || {}, root["__FOUNDATION_EXTERNAL__"]["foundation.responsiveAccordionTabs"] = factory(root["__FOUNDATION_EXTERNAL__"]["foundation.accordion"], root["__FOUNDATION_EXTERNAL__"]["foundation.core"], root["__FOUNDATION_EXTERNAL__"]["foundation.tabs"], root["__FOUNDATION_EXTERNAL__"]["foundation.util.mediaQuery"], root["jQuery"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__foundation_accordion__, __WEBPACK_EXTERNAL_MODULE__foundation_core__, __WEBPACK_EXTERNAL_MODULE__foundation_tabs__, __WEBPACK_EXTERNAL_MODULE__foundation_util_mediaQuery__, __WEBPACK_EXTERNAL_MODULE_jquery__) {
return 